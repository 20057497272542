import Layout from 'components/layout'
import Seo from 'components/molecules/seo'
import CTABlock from 'components/organisms/ctaBlock'
import FeatureImage from 'components/organisms/featureImage'
import Features from 'components/organisms/features'
import Hero from 'components/organisms/hero'
import PullQuotes from 'components/organisms/pullQuotes'
import { graphql } from 'gatsby'
import { getLocalNodes } from 'helpers/locale'
import { useResourceMediaData } from 'hooks/useResourceMediaData'
import { useResourceTextData } from 'hooks/useResourceTextData'
import * as React from 'react'
import {
  ContentfulArticleConnection,
  ContentfulCtaBlockConnection,
  ContentfulHeroPanelConnection,
  ContentfulResourceSetConnection,
  ContentfulTestimonialConnection,
  Site,
} from 'types/graphql-types'

type WholeSaleTypes = {
  data: {
    site: Site
    allContentfulHeroPanel: ContentfulHeroPanelConnection
    allContentfulCtaBlock: ContentfulCtaBlockConnection
    features: ContentfulResourceSetConnection
    requirements: ContentfulResourceSetConnection
    allContentfulArticle: ContentfulArticleConnection
    allContentfulTestimonial: ContentfulTestimonialConnection
    pullQuotes: ContentfulTestimonialConnection
    footerCtaBlock: ContentfulCtaBlockConnection
  }
}

const Wholesale = ({ data }: WholeSaleTypes): JSX.Element => {
  const heroData = getLocalNodes(data.allContentfulHeroPanel.nodes)
  const featuresData = getLocalNodes(data.features.nodes)
  const requirementsData = getLocalNodes(data.requirements.nodes)
  const ctaData = getLocalNodes(data.allContentfulCtaBlock.nodes)
  const footerCtaBlock = getLocalNodes(data.footerCtaBlock.nodes)
  const pullQuotes = getLocalNodes(data.pullQuotes.nodes)

  return (
    <Layout header="trans">
      <Seo title="Wholesale" />
      <Hero {...heroData[0]} image={heroData[0]?.image.fluid} align="center" />

      {featuresData[0] && (
        <Features
          title={useResourceTextData(
            'wholesaleOffice.featureTitle',
            'What an office account offers'
          )}
          items={featuresData[0].resources}
        />
      )}

      <FeatureImage
        title={useResourceTextData(
          'wholesaleOffice.featureImageTitle',
          "Who's eligible"
        )}
        image={useResourceMediaData('wholesaleOffice.featureImage')}
      />

      {requirementsData[0] && (
        <Features items={requirementsData[0].resources} />
      )}

      <PullQuotes items={pullQuotes} />

      {footerCtaBlock[0] && (
        <CTABlock
          body={footerCtaBlock[0]?.body.body}
          cta={footerCtaBlock[0]?.cta}
          alternate
          noMarginPadding
        />
      )}
    </Layout>
  )
}

export default Wholesale

export const pageQuery = graphql`
  query WholeSaleOfficeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulHeroPanel(filter: { key: { eq: "wholesaleOffice.hero" } }) {
      nodes {
        id
        title
        subTitle
        image {
          fluid(maxWidth: 1600) {
            ...Image
          }
        }
        body {
          body
        }
        cta {
          ... on ContentfulLink {
            ...Link
          }
          ... on ContentfulRegionalLink {
            ...RegionalLink
          }
        }
        node_locale
      }
    }

    features: allContentfulResourceSet(
      filter: { name: { eq: "Wholesale office features" } }
    ) {
      nodes {
        resources {
          ... on ContentfulFeature {
            id
            image {
              fluid {
                ...Image
              }
            }
            title
            body
          }
        }
        node_locale
      }
    }

    requirements: allContentfulResourceSet(
      filter: { name: { eq: "Wholesale office requirements" } }
    ) {
      nodes {
        resources {
          ... on ContentfulFeature {
            id
            image {
              fluid {
                ...Image
              }
            }
            title
            body
          }
        }
        node_locale
      }
    }
    allContentfulCtaBlock(filter: { key: { eq: "wholesaleOffice" } }) {
      nodes {
        id
        cta {
          ... on ContentfulLink {
            ...Link
          }
          ... on ContentfulRegionalLink {
            ...RegionalLink
          }
        }
        body {
          body
        }
        images {
          media {
            title
            fluid {
              ...Image
            }
          }
        }
        node_locale
      }
    }
    pullQuotes: allContentfulTestimonial(
      filter: { key: { eq: "wholesaleOffice.pullquote" } }
    ) {
      nodes {
        node_locale
        quote
        authorImage {
          fluid {
            ...Image
          }
        }
        byline
      }
    }
    footerCtaBlock: allContentfulCtaBlock(
      filter: { key: { eq: "wholesaleOffice.footerCta" } }
    ) {
      nodes {
        id
        cta {
          ... on ContentfulLink {
            ...Link
          }
          ... on ContentfulRegionalLink {
            ...RegionalLink
          }
        }
        body {
          body
        }
        node_locale
      }
    }
  }
`
